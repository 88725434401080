<template>
  <div v-if="user">
    <b-row>
      <b-col>
        <b-card>
          <b-row align-h="between" class="px-1 mb-1">
            <div class="d-flex">
              <feather-icon size="19" icon="UserIcon" />
              <h4 class="mb-1 ml-50">{{ $t('admin.users.userInfo') }}</h4>
            </div>
          </b-row>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :text="avatarText(user.name)"
                  :variant="`light-${resolveUserRoleVariant(user.role)}`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ user.name + ' ' + user.lastName }}
                    </h4>
                    <span class="card-text">{{ user.email }}</span>
                  </div>
                  <b-button
                    @click="resetPassword()"
                    size="sm"
                    style="padding: 5px"
                    variant="outline-warning"
                    >{{ $t('admin.users.resetPassword') }}</b-button
                  >
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="6" lg="6" md="6" sm="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="CalendarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t('admin.labs.profile.infoCard.createdOn')
                    }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ parseDate(user.createdAt) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t('admin.labs.profile.infoCard.role')
                    }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ getRoleString(user.role) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t('admin.labs.profile.infoCard.cpf')
                    }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ user.cpf | cpf }}
                  </td>
                </tr>
                <tr v-if="!isSuperAdmin">
                  <th class="pb-50">
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t('admin.labs.profile.infoCard.contact')
                    }}</span>
                  </th>
                  <td>
                    {{ user.phoneNumber ? user.phoneNumber : '-' }}
                  </td>
                </tr>
                <tr v-if="!isSuperAdmin && !isSeller">
                  <th class="pb-50">
                    <feather-icon icon="MapIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t('admin.labs.profile.infoCard.address')
                    }}</span>
                  </th>
                  <td class="pb-50">
                    {{
                      `${user.address.line1 || ''} ${
                        user.address.line2 || ''
                      }. ${user.address.number || ''} - ${
                        user.address.zipCode || ''
                      }, ${user.address.country || ''}`
                    }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4">
        <profile-plan-card />
      </b-col>
    </b-row>

    <b-card v-if="entityData">
      <b-row class="px-1 mb-1">
        <div class="d-flex">
          <feather-icon size="19" icon="PaperclipIcon" />
          <h4 class="mb-0 ml-50">{{ getRoleTitle }}</h4>
        </div>
      </b-row>
      <b-row>
        <b-col>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t('admin.users.createdAt')
                }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ parseDate(entityData.createdAt) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t('admin.users.updatedAt')
                }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ parseDate(entityData.createdAt) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t('admin.users.fantasyName')
                }}</span>
              </th>
              <td>
                {{ entityData.nameFantasy }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t('admin.users.registeredName')
                }}</span>
              </th>
              <td>
                {{ entityData.nameRegistered }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="MapIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t('admin.users.address')
                }}</span>
              </th>
              <td class="pb-50">
                {{
                  `${entityData.address.line1 || ''} ${
                    entityData.address.line2 || ''
                  }. ${entityData.address.number || ''} - ${
                    entityData.address.zipCode || ''
                  }, ${entityData.address.country || ''}`
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PocketIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t('opticForm.sectionField8')
                }}</span>
              </th>
              <td class="pb-50">
                {{ entityData.cnpj | cnpj }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col v-if="!isLoading">
          <b-col
            cols="12"
            lg="12"
            v-if="entityData.status === 'pending-authorization'"
          >
            <!-- Choose between Stripe and Lensxpert plans -->
            <b-form-group
              label="Choose type of billing"
              v-slot="{ ariaDescribedby }"
            >
              <b-row class="mx-0">
                <b-form-radio
                  v-model="planType"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="external"
                  >External</b-form-radio
                >
                <b-form-radio
                  class="ml-1"
                  v-model="planType"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="stripe"
                  >Stripe</b-form-radio
                >
              </b-row>
            </b-form-group>
            <div v-if="planType === 'stripe'">
              <b-row align-v="center" align-h="between">
                <b-col cols="8">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('admin.labs.form.subscriptionPlan')"
                  >
                    <b-form-group
                      :label="$t('admin.labs.form.subscriptionPlan')"
                      label-for="subscription"
                    >
                      <v-select
                        v-model="subscription"
                        label="description"
                        :placeholder="$t('admin.labs.form.subscriptionPlan')"
                        :options="plans"
                        :clearable="false"
                      />
                    </b-form-group>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- Is trial Plan -->
                <b-col v-if="subscription">
                  <b-row align-h="end" class="mr-1">
                    <span class="mr-1">{{
                      $t('admin.labs.softwarePartnerForm.trialPlan')
                    }}</span>
                    <b-form-checkbox v-model="isTrialPlan" />
                  </b-row>
                </b-col>
              </b-row>
              <validation-provider
                v-if="isTrialPlan"
                #default="validationContext"
                rules="required|integer|positive"
                :name="$t('admin.labs.form.trialDays')"
              >
                <b-form-group
                  :label="$t('admin.labs.form.trialDays')"
                  label-for="trial-days"
                >
                  <b-form-input
                    v-model="trialDays"
                    id="trial-days"
                    :placeholder="$t('admin.labs.form.trialDays')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div v-if="planType === 'external'">
              <validation-provider
                #default="validationContext"
                :name="$t('admin.labs.form.subscriptionPlan')"
              >
                <b-form-group
                  :label="$t('admin.labs.form.subscriptionPlan')"
                  label-for="subscription"
                >
                  <v-select
                    v-model="subscription"
                    label="name"
                    :placeholder="$t('admin.labs.form.subscriptionPlan')"
                    :options="externalPlans"
                    :clearable="false"
                  />
                </b-form-group>

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </div>

            <b-button
              :disabled="!subscription"
              :variant="subscription ? 'outline-success' : 'light'"
              @click="approveUserEntity()"
            >
              <span>{{ $t('admin.users.approve') }}</span>
            </b-button>
          </b-col>
          <b-button
            v-else-if="entityData.status === 'pending-payment'"
            disabled
            variant="outline-warning"
            >{{ $t('admin.users.pendingPayment') }}</b-button
          >
          <b-button v-else disabled variant="outline-dark">{{
            $t('admin.users.approved')
          }}</b-button>
        </b-col>

        <b-col v-else>
          <b-row align-h="start" align-v="center" class="mr-4">
            <p class="mr-2 mt-1">{{ $t('admin.users.loading') }}</p>
            <b-spinner />
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <invoices-list :UserId="userId"> </invoices-list>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInvalidFeedback,
  BBadge,
  BAvatar,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import { cpf, cnpj } from '@/utils/formatters'
import RoleEnum from '@/utils/roles'
import store from '@/store'

import { onUnmounted } from 'vue-demi'
import { avatarText } from '@core/utils/filter'

import userStoreModule from '@/views/admin/users/userStoreModule'
import softwarePartnerStoreModule from '@/views/sections/software-partners/softwarePartnerStoreModule'
import labStoreModule from '@/views/sections/labs/labStoreModule'
import opticStoreModule from '@/views/sections/optics/opticStoreModule'
import planStoreModule from '@/views/admin/plans/planStoreModule'

import InvoicesList from '@/views/admin/invoices/InvoicesList.vue'
import CheckoutSubscriptionSession from '@/entities/checkout-session-subscription.entity'
import SubscriptionController from '@/controllers/subscriptions.controller'
import UserController from '@/controllers/user.controller'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthController from '@/controllers/auth.controller'
import ProfilePlanCard from '@/views/sections/profile/ProfilePlanCard.vue'
import PlanController from '@/controllers/plan.controller'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BForm,
    BFormInvalidFeedback,
    BBadge,
    BFormCheckbox,
    BFormRadio,

    ValidationProvider,
    ValidationObserver,
    BAvatar,

    ProfilePlanCard,
    InvoicesList,
    vSelect,
  },
  data() {
    return {
      required,
      user: null,
      entityData: null,
      subscription: null,
      isTrialPlan: false,
      trialDays: null,
      isLoading: false,
      userId: this.$route.params.id,
      planType: null,
    }
  },

  filters: {
    /**
     * Gets a formatted CPF.
     *
     * @param {string} value the CPF to be formatted.
     */
    cpf(value) {
      return cpf(value.replace(/[^\d]/g, ''))
    },

    /**
     * Gets a formatted CNPJ.
     *
     * @param {string} value the CNPJ to be formatted.
     */
    cnpj(value) {
      return cnpj(value.replace(/[^\d]/g, ''))
    },
  },

  computed: {
    qtyMeasurements() {
      return this.additionalData ? this.additionalData.qtyMeasurements || 0 : 0
    },
    qtySellers() {
      return this.additionalData ? this.additionalData.qtySellers || 0 : 0
    },
    isSuperAdmin() {
      return this.user.role === RoleEnum.SUPER_ADMIN
    },
    isSeller() {
      return this.user.role === RoleEnum.SELLER
    },
    getRoleTitle() {
      return RoleEnum.getRoleTitle(this.user.role)
    },
    loading() {
      return this.$store.state.user.loading
    },
    canShowAddress() {
      const role = this.user.role
      if (role != RoleEnum.SUPER_ADMIN && role != RoleEnum.SELLER) return true
      else return false
    },
    plans() {
      let product = null
      switch (this.user.role) {
        case RoleEnum.SOFTWARE_ADMIN:
          product = 'software-partner'
          break
        case RoleEnum.LABORATORY_ADMIN:
          product = 'laboratory'
          break
        case RoleEnum.OPTIC_ADMIN:
          product = 'optic'
          break
      }

      const entityPlan = this.$store.state.plan.plans.find(
        (el) => el.metadata && el.metadata.type === product,
      )
      return (
        entityPlan?.prices
          .filter((el) => el.active == true)
          .sort((a, b) => a.description.localeCompare(b.description)) ?? []
      )
    },
    externalPlans() {
      return this.$store.state.plan.plans_lensxpert || []
    },
  },
  methods: {
    avatarText,
    async resetPassword() {
      new AuthController()
        .resetPassword(this.user.email)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('toast.resetPassword.success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch((e) => {
          console.error(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('toast.resetPassword.failure'),
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    async approveUserEntity() {
      this.isLoading = true

      try {
        let dispatchPath = ''
        let entityPath = ''

        switch (this.user.role) {
          case RoleEnum.SOFTWARE_ADMIN:
            dispatchPath = 'software-partner/setSoftwarePartner'
            entityPath = `software-partners/${this.entityData.id}`
            break
          case RoleEnum.LABORATORY_ADMIN:
            dispatchPath = 'laboratory/setLaboratory'
            entityPath = `laboratories/${this.entityData.id}`
            break
          case RoleEnum.OPTIC_ADMIN:
            dispatchPath = 'optic/setOptic'
            entityPath = `optics/${this.entityData.id}`
            break
        }

        if (this.planType === 'stripe') {
          // Send user a payment link
          const customer = await this.$store.dispatch(
            'user/fetchOneCustomer',
            this.user.id,
          )

          const successUrl = `${window.location.origin}/welcome-payment?p=${this.user.adminDocRef}&ss=true&id=${this.entityData.id}&u=${this.user.id}&r=${this.user.role}`
          const failedUrl = `${window.location.origin}/welcome-payment?p=${this.user.adminDocRef}&ss=false&id=${this.entityData.id}&u=${this.user.id}&r=${this.user.role}`

          const sessionObj = new CheckoutSubscriptionSession({
            customer: customer.stripeId,
            lineItems: [{ price: this.subscription.id, quantity: 1 }],
            successUrl: successUrl,
            cancelUrl: failedUrl,
          })

          if (this.isTrialPlan) {
            sessionObj.subscriptionData = {
              trial_period_days: this.trialDays,
            }
          }
          const subscriptionController = new SubscriptionController()
          const sessionResult = await subscriptionController.subscribeToProduct(
            sessionObj,
          )

          const emailData = {
            company: this.entityData.nameRegistered,
            paymentUrl: sessionResult.data.data.url,
            toEmail: customer.email,
            subject: 'Realize o pagamento',
            name: 'Lensxpert',
            fromEmail: '',
          }
          await new UserController().sendPaymentEmail(emailData)
          // Update entity
          this.entityData.status = 'pending-payment'
          await store.dispatch(dispatchPath, {
            id: this.entityData.id,
            data: this.entityData,
            parent: this.user.adminDocRef,
          })

          // Update user
          this.user.status = 'pending-payment'
          await store.dispatch('user/updateUserInfo', {
            id: this.user.id,
            data: this.user,
          })
        } else if (this.planType === 'external') {
          // Send email granting access
          const emailData = {
            company: this.entityData.nameRegistered,
            toEmail: this.user.email,
            subject: 'Bem vindo ao Lensxpert',
            name: 'Lensxpert',
            fromEmail: '',
          }
          await new UserController().sendAccessGrantedEmail(emailData)

          // Update entity
          await new PlanController().subscribeToExternalPlan({
            userId: this.user.id,
            planId: this.subscription.id,
            entityPath: entityPath,
          })

          // Update user
          this.user.status = 'active'
          await store.dispatch('user/updateUserInfo', {
            id: this.user.id,
            data: this.user,
          })
        }
        this.$router.push({ name: 'users' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('toast.paymentSent.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        console.error(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('toast.paymentSent.failure'),
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getRoleString(role) {
      return RoleEnum.getRoleAsString(role)
    },
    openEditModal() {
      this.$emit('edit-modal', true)
    },
    resolveUserRoleVariant(role) {
      switch (role) {
        case RoleEnum.SUPER_ADMIN:
          return 'danger'
        case RoleEnum.SOFTWARE_ADMIN:
          return 'warning'
        case RoleEnum.LABORATORY_ADMIN:
          return 'primary'
        case RoleEnum.OPTIC_ADMIN:
          return 'info'
        case RoleEnum.SELLER:
          return 'success'
        default:
          return 'primary'
      }
    },
    parseDate(date) {
      return new Date(date).toDateString()
    },
    canSeeStats() {
      if (
        this.user.role != RoleEnum.SELLER &&
        this.user.role != RoleEnum.SUPER_ADMIN
      ) {
        return true
      } else {
        return false
      }
    },
    cancel() {
      this.$router.push({ name: 'users' })
    },
  },
  async created() {
    this.user = await this.$store.dispatch(
      'user/fetchOneUser',
      this.$route.params.id,
    )

    let dispatchPath = ''
    if (this.user.role === RoleEnum.SOFTWARE_ADMIN) {
      dispatchPath = 'software-partner/fetchOneSoftwarePartner'
    } else if (this.user.role === RoleEnum.LABORATORY_ADMIN) {
      dispatchPath = 'laboratory/fetchOneLab'
    } else if (this.user.role === RoleEnum.OPTIC_ADMIN) {
      dispatchPath = 'optic/fetchOneOptic'
    }

    this.entityData = await store.dispatch(dispatchPath, this.user.adminDocRef)
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user'
    const SOFTWARE_PARTNER_APP_STORE_MODULE_NAME = 'software-partner'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const PLAN_APP_STORE_MODULE_NAME = 'plan'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        SOFTWARE_PARTNER_APP_STORE_MODULE_NAME,
        softwarePartnerStoreModule,
      )
    }

    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }
    onUnmounted(() => {
      store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)

      store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)

      store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    )

    store.dispatch('plan/fetchPlans')
    store.dispatch('plan/fetchPlansLensxpert')

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
