import { computed } from '@vue/composition-api'
import i18n from '@/libs/i18n'

export default function useInvoicesList() {
  const tableColumns = computed(() => {
    return [
      { key: 'number', label: 'Nº', sortable: true },
      {
        key: 'period_start',
        label: i18n.t('admin.invoices.table.initial-period'),
        sortable: false,
      },
      {
        key: 'period_end',
        label: i18n.t('admin.invoices.table.final-period'),
        sortable: false,
      },
      {
        key: 'status',
        label: i18n.t('admin.invoices.table.stats'),
        sortable: false,
      },
      {
        key: 'total',
        label: i18n.t('admin.invoices.table.total'),
        sortable: false,
      },
      {
        key: 'invoice_pdf',
        label: i18n.t('admin.invoices.table.PDF'),
        sortable: false,
      },
    ]
  })

  return {
    tableColumns,
  }
}
