<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row align-h="between" class="mx-0">
          <div class="d-flex">
            <feather-icon icon="DollarSignIcon" size="19" />
            <h4 class="mb-0 ml-50">{{ $t('admin.invoices.invoices') }}</h4>
          </div>
        </b-row>
      </div>
      <b-table
        id="my-table"
        ref="refInvoicesListTable"
        class="position-relative"
        responsive
        show-empty
        :items="invoices"
        :fields="tableColumns"
        :empty-text="$t('admin.measurements.list.noEntries')"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">{{
              $t('admin.measurements.list.loading')
            }}</strong>
          </div>
        </template>

        <template #cell(period_start)="data">
          {{ getPeriod(data.item, 'start') }}
        </template>
        <template #cell(period_end)="data">
          {{ getPeriod(data.item, 'end') }}
        </template>

        <template #cell(status)="data">
          <b-badge
            style="margin: 3px"
            :variant="getStatusVariant(data.item.status)"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(total)="data">
          {{ formatPrice(data.item.total) }}
        </template>

        <template #cell(invoice_pdf)="data">
          <a :href="data.item.invoice_pdf" target="_blank">
            Download <feather-icon icon="FileTextIcon" />
          </a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import useInvoicesList from './useInvoicesList'
import {
  beautyParserDateToString,
  fromTimestampToString,
} from '../../../utils/dates'

export default {
  props: ['UserId'],
  data() {
    return {
      invoices: [],
      beautyParserDateToString,
      fromTimestampToString,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,
  },
  methods: {
    getPeriod(item, period) {
      const line = item.lines.data[0]

      if (!line) {
        return '-'
      }

      const start = line.period.start
      const end = line.period.end

      return this.fromTimestampToString(period === 'end' ? end : start)
    },
    getStatusVariant(status) {
      if (status === 'paid') return 'success'
      else return 'warning'
    },
    formatPrice(unit_amount) {
      return `R$ ${unit_amount / 100}`
    },
  },
  async created() {
    this.invoices = await this.$store.dispatch(
      'fetchOneUserInvoice',
      this.UserId,
    )
  },
  setup() {
    const { tableColumns } = useInvoicesList()

    return {
      tableColumns,
    }
  },
}
</script>

<style></style>
